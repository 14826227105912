import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './RoomSize.styles.css';

//Redux
import { useDispatch } from 'react-redux';
import { selectRoomSize } from '../../redux/actions/optionActions';

//imgs
import onePeople from '../../assets/images/1-3-people.png';
import fivePeople from '../../assets/images/5-10-people.png';
import tenPeople from '../../assets/images/10-more-people.png';

//Componets
import Header from '../Header/Header';
import Steps from '../Steps/Steps';

function RoomSize(props) {
  const dispatch = useDispatch();

  const goToType = (opt, abrv) => {
    let obj = {
      name: opt,
      abre: abrv
    }

    dispatch(selectRoomSize(obj));
    props.history.push('/type-screen');
  }

  return (
    <Container className="customContainer">
      <Header title={'Room Size'} showMobileMenu={false} />
      <Row>
        <Col xs="12" md="3"></Col>
        <Col xs="12" md="6" style={{textAlign: 'center', paddingTop: 30}}>
          <p className="subtitleRoom">What is your meeting room size?</p>
          <p className="text">Select your room size based on the average number of users per meeting</p>

          <Row>
            <Col xs="4" md="4">
              <img className="imgSize" src={onePeople} alt=""/>
              <p className="txtPeople">1-3 people</p>

              <div className="btnStart" onClick={() => goToType('Small', 'S')}>
                <span className="txtBtn">Small</span>
              </div>
            </Col>
            <Col xs="4" md="4">
              <img className="imgSize" src={fivePeople} alt=""/>
              <p className="txtPeople">5-10 people</p>

              <div className="btnStart" onClick={() => goToType('Medium', 'M')}>
                <span className="txtBtn">Medium</span>
              </div>
            </Col>
            <Col xs="4" md="4">
              <img className="imgSize" src={tenPeople} alt=""/>
              <p className="txtPeople">10+ people</p>

              <div className="btnStart" onClick={() => goToType('Large', 'L')}>
                <span className="txtBtn">Large</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Steps active={1} />
          </Row>
        </Col>
        <Col xs="12" md="3"></Col>
      </Row>
    </Container>
  )
}

export default RoomSize;