import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './MobilitySolution.styles.css';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { selectMobilitySolution } from '../../redux/actions/optionActions';

//imgs
import mobFixed from '../../assets/images/mobFixed.png';
import mobAdjustable from '../../assets/images/mobAdjustable.png';
import mobMobile from '../../assets/images/mobMobile.png';

//Componets
import Header from '../Header/Header';
import Steps from '../Steps/Steps';
import SideMenu from '../SideMenu/SideMenu';

function MobilitySolution(props) {
  const dispatch = useDispatch();
  const typeScreen = useSelector(state => state.optionsReducer.typeScreen);
  const toggleSide = useSelector(state => state.optionsReducer.toggleSide);

  const goToMobility = (opt, abrv) => {
    let obj = {
      name: opt,
      abre: abrv
    }
    dispatch(selectMobilitySolution(obj));
    if(typeScreen.type === 'HDi'){
      props.history.push('/ops');
    }else{
      props.history.push('/video-conferencing');
    }
  }

  return (
    <Container className="customContainer">
      <Header title={'Mobility Solution'} showMobileMenu={true} />
      <Row>
        <Col xs="12" md="3"></Col>
        <Col xs="12" md="6" style={{textAlign: 'center', paddingTop: 30}}>
          <p className="subtitle">How would you like your screen to be configured?</p>
          <Row className="spaceRowTop">
            <Col xs="4" md="4">
              <img className="imgSize" src={mobFixed} alt=""/>

              <div className="btnStart" onClick={() => goToMobility('Wallmount fixed', 'WF')}>
                <span className="txtBtn">Wallmount fixed</span>
              </div>
            </Col>
            <Col xs="4" md="4">
              <img className="imgSize" src={mobAdjustable} alt=""/>

              <div className="btnStart" onClick={() => goToMobility('Wallmount Adjustable', 'WA')}>
                <span className="txtBtn">Wallmount Adjustable</span>
              </div>
            </Col>
            <Col xs="4" md="4">
              <img className="imgSize" src={mobMobile} alt=""/>

              <div className="btnStart" onClick={() => goToMobility('Mobile', 'MO')}>
                <span className="txtBtn">Mobile</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Steps active={3} />
          </Row>
        </Col>
        {toggleSide && <Col xs="12" md="3" className="slideMenuColMo" style={{paddingRight: 0}}>
          <SideMenu step={2} />
        </Col>}
      </Row>
      <Row>
        <div className="contentTip">
          <div className="iconTip"></div>
          <p className="txtTip">If wall-mounted for 65" or greater we recommend the wall-mount adjustable option.</p>
        </div>
      </Row>
    </Container>
  )
}

export default MobilitySolution;