import React from 'react';
import './Steps.styles.css';

function Steps(props) {
  const { active } = props;
  return (
    <div className="contentSteps">
      <div className="step">{active !== 1 && <span className="blackpoint"></span>}</div>
      <div className="step">{active !== 2 && <span className="blackpoint"></span>}</div>
      <div className="step">{active !== 3 && <span className="blackpoint"></span>}</div>
      <div className="step">{active !== 4 && <span className="blackpoint"></span>}</div>
      <div className="step">{active !== 5 && <span className="blackpoint"></span>}</div>
      <div className="step">{active !== 6 && <span className="blackpoint"></span>}</div>
    </div>
  )
}

export default Steps;