import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './Home.styles.css';
import bg from '../../assets/images/meetGroup.png'

//Redux
import { useDispatch } from 'react-redux';
import { setToggle, setReseller } from '../../redux/actions/optionActions';

//Componets
import Header from '../Header/Header';

function Home(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    validateMobile();
    validateSubdomain();
  }, []);

  const validateSubdomain = () => {
    var subdomain = window.location.hostname;
    let reseller = subdomain.split('.')[0];
    if (reseller) {
      dispatch(setReseller(reseller));
    } else {
      dispatch(setReseller("roombuilder"));
    }
  }

  const validateMobile = () => {
    var w = window.innerWidth;
    if (w <= 500) {
      dispatch(setToggle(true))
    } else {
      dispatch(setToggle(false))
    }
  }

  const goToSize = () => {
    props.history.push('/room-size');
  }

  return (
    <Container className="customContainer">
      <Header title={'Welcome'} showMobileMenu={false} />
      <Row>
        <Col xs="12" md="3"></Col>
        <Col xs="12" md="6" style={{ textAlign: 'center' }} className="bgImage homeTop">
          <img className="bgGroup" src={bg} alt="" />
          <p className="subtitle">HDi Room Builder</p>
          <p className="text">This easy-to-use app will help you to build your meeting and collaborative spaces. Within 5 simple questions, this Room Builder app will compile a list of technologies best suited to your environment and create a schematic illustrating all connections within your new space.</p>
          <div className="btnHome"
            onClick={goToSize}
          >
            <span className="txtBtn">Start</span>
          </div>
        </Col>
        <Col xs="12" md="3"></Col>
      </Row>
    </Container>
  )
}

export default Home;