import React, { useState } from 'react';
import { Container, Row, Col, FormGroup, Input, Spinner } from 'reactstrap';
import './YourRoom.styles.css';

//Axios
import axios from 'axios';

// Serivces
import { postSubmitData } from '../../services/apiService';

//Redux
import { useSelector } from 'react-redux';

//Componets
import Header from '../Header/Header';
import SideMenu from '../SideMenu/SideMenu';

function YourRoom(props) {
  const toggleSide = useSelector(state => state.optionsReducer.toggleSide);
  const roomSize = useSelector(state => state.optionsReducer.roomSize);
  const typeScreen = useSelector(state => state.optionsReducer.typeScreen);
  const mobilitySolution = useSelector(state => state.optionsReducer.mobilitySolution);
  const ops = useSelector(state => state.optionsReducer.ops);
  const videoConferencing = useSelector(state => state.optionsReducer.videoConferencing);
  const wirelessSharing = useSelector(state => state.optionsReducer.wirelessSharing);
  const reseller = useSelector(state => state.optionsReducer.reseller);
  const [values, setValues] = useState({
    fullname: "",
    email: "",
    phone: 0,
    reseller: "",
    terms: false
  });
  const [errors, setErrors] = useState({
    fullname: false,
    phone: false,
    reseller: false,
    email: false,
    terms: false,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    let key = e.target.id;
    let val = e.target.value;

    if (key === 'terms') {
      val = e.target.checked;
    }

    setValues({ ...values, [key]: val });
  }

  const validField = (e) => {
    let key = e.target.id;
    let value = e.target.value;

    if (value.length === 0) {
      setErrors({ ...errors, [key]: true });
    } else {
      setErrors({ ...errors, [key]: false });
    }
  }

  const submitForm = () => {
    setLoading(true);
    if (values.fullname !== "" && values.email !== "" && values.terms === true) {
      setErrors({
        fullname: false,
        phone: false,
        reseller: false,
        email: false,
        terms: false,
      });
      sendMailWithRoom();
    } else {
      setLoading(false);
      setErrors({
        fullname: true,
        phone: true,
        email: true,
        terms: true,
        reseller: true
      });
    }
  }

  const concatPdfName = () => {
    if (typeScreen.type === 'HDi') {
      return [roomSize.abre, typeScreen.abre, mobilitySolution.abre, ops.abre, videoConferencing.abre, wirelessSharing.abre].join('_');
    } else {
      return [roomSize.abre, typeScreen.abre, mobilitySolution.abre, videoConferencing.abre, wirelessSharing.abre].join('_');
    }
  }

  const sendMailWithRoom = async () => {
    var document = concatPdfName();
    var owner = 'roombuilder@hdinteractive.com.au';

    await axios.get(`https://mailing.multimedialab.dev/sendMail.php?mail=${values.email}&pdf=${document}.pdf&type=0&username=${values.fullname}&email=${values.email}&phone=${values.phone}&reseller=${reseller}&subject=HDi Room Builder Schematic`, { headers: { 'Content-Type': 'application/json' } });

    await axios.get(`https://mailing.multimedialab.dev/sendMail.php?mail=${owner}&pdf=${document}.pdf&type=1&username=${values.fullname}&email=${values.email}&phone=${values.phone}&reseller=${reseller}&subject=HDi Room Builder Submission`, { headers: { 'Content-Type': 'application/json' } });

    let objPost = [{
      fullname: values.fullname,
      email: values.email,
      phone: values.phone,
      reseller,
      terms: values.terms,
      pdfName: `${document}.pdf`,
      date: new Date()
    }]

    postSubmitData(objPost).then(resp => {
      console.log('resp', resp);
      setLoading(false);
      props.history.push('/thank-you');
    });
  }

  return (
    <Container className="customContainer">
      <Header title={'Your Room'} showMobileMenu={true} />
      <Row>
        <Col xs="12" md="3"></Col>
        <Col xs="12" md="6" style={{ textAlign: 'center', paddingTop: 30 }}>
          <p className="titleRoom">Now that you have built your room, let us send you a Schematic diagram showing</p>
          <p className="titleRoom">you how to bring this room to life.</p>
          <Row style={{ marginTop: 40 }}>
            <Col xs="12" md="12">
              <FormGroup>
                <Input
                  id="fullname"
                  type="text"
                  placeholder="Full Name*"
                  className="customInput"
                  onChange={handleChange}
                  invalid={errors.fullname}
                  onBlur={validField}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="12">
              <FormGroup>
                <Input
                  id="email"
                  type="text"
                  placeholder="Email Address*"
                  className="customInput"
                  onChange={handleChange}
                  invalid={errors.email || !/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(values.email)}
                  onBlur={validField}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="12">
              <FormGroup>
                <Input
                  id="phone"
                  type={values.phone.toString().length === 12 ? "text" : "number"}
                  placeholder="Phone"
                  className="customInput"
                  maxLength={10}
                  min="0"
                  onChange={handleChange}
                // invalid={errors.phone}
                // onBlur={validField}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="12" style={{ display: 'none' }}>
              <FormGroup>
                <Input
                  id="reseller"
                  type="text"
                  placeholder="Preferred reseller"
                  className="customInput"
                  value={reseller}
                  disabled
                // onChange={handleChange}
                // invalid={errors.reseller}
                // onBlur={validField}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="12" className="rowSubmit">
              <FormGroup check>
                <Input
                  id="terms"
                  type="checkbox"
                  onChange={handleChange}
                /> <span className="txtTerms"><a style={{ color: `${errors.terms ? 'red' : '#fff'}` }} href="https://hdinteractive.com.au/terms-and-conditions/" target="_blank" rel="noreferrer">Accept Terms and Conditions</a></span>
              </FormGroup>
              <div className="btnSubmit" onClick={submitForm}>
                {!loading && <span className="txtBtn">Submit</span>}
                {loading && <Spinner type="grow" size="md" color="dark" />}
              </div>
            </Col>
          </Row>

          <Row className="spaceExperts">
            <Col xs="12" md="12">
              <p className="textExperts">Or talk with our technology experts</p>
              <div className="rowIconPhone">
                <div className="phoneIcon"></div>
                <p className="textPhone"><a style={{ color: "#F3F3F3" }} href="tel:1300953907">1300 953 907</a></p>
              </div>
            </Col>
          </Row>
        </Col>
        {toggleSide && <Col xs="12" md="3" className="slideMenuCol" style={{ paddingRight: 0 }}>
          <SideMenu step={6} />
        </Col>}
      </Row>
    </Container>
  )
}

export default YourRoom;