import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import './VideoConferencing.styles.css';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { selectVideo } from '../../redux/actions/optionActions';

//Images
import videoConfe from '../../assets/images/videoConfe.png';

//Icons
import zoomIcon from '../../assets/images/icons/zoomIcon.png'
import teamsIcon from '../../assets/images/icons/teamsIcon.png'
import skypeIcon from '../../assets/images/icons/skypeIcon.png'

//Components
import Header from '../Header/Header';
import Steps from '../Steps/Steps';
import SideMenu from '../SideMenu/SideMenu';

function VideoConferencing(props) {
  const dispatch = useDispatch();
  const toggleSide = useSelector(state => state.optionsReducer.toggleSide);
  const goToWireless = (opt, abrv) => {
    let obj = {
      name: opt,
      abre: abrv
    }
    dispatch(selectVideo(obj));
    props.history.push('/wireless-sharing');
  }

  return (
    <Container className="customContainer">
      <Header title={'Video Conferencing'} showMobileMenu={true} />
      <Row>
        <Col xs="12" md="3"></Col>
        <Col xs="12" md="6" style={{textAlign: 'center'}} className="topVideo">
          <Row>
            <Col xs="12" md="6">
              <img className="imgVideoConfe" src={videoConfe} alt=""/>
            </Col>
            <Col xs="12" md="6" className="centerWhite">
              <div className="contentDescriptionV">
                <p className="mainText">What is Video</p>
                <p className="mainText">Conferencing?</p>
                <p className="secondText" style={{marginTop: 15}}>Video conferencing systems allow users in different locations to hold face-to-face meetings remotely, during times when a physical presence is not possible. Video Conferencing requires both software and hardware to work properly.</p>
                <div className="contentNetworks">
                  <img src={zoomIcon} width="86px" />
                  <img src={teamsIcon} width="47px" />
                  <img src={skypeIcon} width="46px" />
                </div>
              </div>
            </Col>
          </Row>

          <Row style={{justifyContent: 'center'}}>
            <p className="secondSubtitle">Will you be using your room for Video Conferencing?</p>
            <div className="contentBtns">
              <div className="btnWhite" onClick={() => goToWireless('Yes', 'VY')}>
                <span className="txtBtn">Yes</span>
              </div>
            
              <div className="btnWhite" onClick={() => goToWireless('No', 'VN')}>
                <span className="txtBtn">No</span>
              </div>
            </div>
          </Row>

          <Row>
            <Steps active={5} />
          </Row>
        </Col>
        {toggleSide && <Col xs="12" md="3" className="slideMenuColVideo" style={{paddingRight: 0}}>
          <SideMenu step={4} />
        </Col>}
      </Row>
    </Container>
  )
}

export default VideoConferencing;