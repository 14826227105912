import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import './ThankYou.styles.css';

//Redux
import { useSelector } from 'react-redux';

//imgs
import finishImg from '../../assets/images/finishImg.png';

//Componets
import Header from '../Header/Header';
import SideMenu from '../SideMenu/SideMenu';

function ThankYou(props) {
  const toggleSide = useSelector(state => state.optionsReducer.toggleSide);
  const roomSize = useSelector(state => state.optionsReducer.roomSize);
  const typeScreen = useSelector(state => state.optionsReducer.typeScreen);
  const mobilitySolution = useSelector(state => state.optionsReducer.mobilitySolution);
  const ops = useSelector(state => state.optionsReducer.ops);
  const videoConferencing = useSelector(state => state.optionsReducer.videoConferencing);
  const wirelessSharing = useSelector(state => state.optionsReducer.wirelessSharing);

  const history = useHistory();

  const startAgain = () => {
    history.push('/');
  }

  const concatPdfName = () => {
    if(typeScreen.type === 'HDi'){
      return [roomSize.abre, typeScreen.abre, mobilitySolution.abre, ops.abre, videoConferencing.abre, wirelessSharing.abre].join('_');
    }else{
      return [roomSize.abre, typeScreen.abre, mobilitySolution.abre, videoConferencing.abre, wirelessSharing.abre].join('_');
    }
  }

  const openPdf = () => {
    var url = concatPdfName();
    window.open(`http://hdiroombuilder.dominiopruebas.com/pdfs/${url}.pdf`, 'blank');
  }

  return (
    <Container className="customContainer">
      <Header title={'Thank you!'} showMobileMenu={true} />
      <Row>
        <Col xs="12" md="3"></Col>
        <Col xs="12" md="6" style={{textAlign: 'center', paddingTop: 50}}>
          <img src={finishImg} className="imgFinish" />
          <p className="textFinish">We have sent you an email with the Schematic diagram, if you want to</p>
          <p className="textFinish">speak to our technology experts call us or book a FREE online session.</p>
          <br />
          {/* <p className="textFinish" style={{cursor: 'pointer'}} onClick={openPdf}>Your Room --- {concatPdfName()}.pdf</p> */}
          <Row style={{marginTop: 40, justifyContent: 'center', flexWrap: 'wrap'}}>
            <div className="contentFinishBtns">
              <div className="btnIcon" onClick={startAgain}>
                <div className="iconPhone"></div>
                <span className="txtBtnIcon">1300 953 907</span>
              </div>

              <a href="https://hdinteractive.com.au/book-free-online-demo/" target="_blank" style={{textDecoration: 'none'}}>
                <div className="btnIcon">
                  <div className="iconCalendar"></div>
                  <span className="txtBtnIcon">Book a FREE Session</span>
                </div>
              </a>

            </div>

            <div className="btnStartAgain" onClick={startAgain}>
              <span className="txtBtnAgain">Start Again</span>
            </div>
          </Row>
        </Col>
        {toggleSide && <Col xs="12" md="3" className="slideMenuCol" style={{paddingRight: 0}}>
          <SideMenu step={6} />
        </Col>}
      </Row>
    </Container>
  )
}

export default ThankYou;