import { TOGGLESIDE, SETROOMSIZE, SETTYPESCREEN, SETMOBILITY, SETOPS, SETVIDEO, SETWIRELESS, SETSUBDOMAIN } from '../constants/action-types';

export const setToggle = bool => ({
  type: TOGGLESIDE,
  toggleSide: bool
});

export const selectRoomSize = obj => ({
  type: SETROOMSIZE,
  roomSize: obj
});

export const selectTypeScreen = obj => ({
  type: SETTYPESCREEN,
  typeScreen: obj
});

export const selectMobilitySolution = obj => ({
  type: SETMOBILITY,
  mobilitySolution: obj
});

export const selectOps = obj => ({
  type: SETOPS,
  ops: obj
});

export const selectVideo = obj => ({
  type: SETVIDEO,
  videoConferencing: obj
});

export const selectWireless = obj => ({
  type: SETWIRELESS,
  wirelessSharing: obj
});

export const setReseller = reseller => ({
  type: SETSUBDOMAIN,
  reseller
});