import { TOGGLESIDE, SETROOMSIZE, SETTYPESCREEN, SETMOBILITY, SETOPS, SETVIDEO, SETWIRELESS, SETSUBDOMAIN } from '../constants/action-types';

const getInitialState = () => ({
  toggleSide: true,
  reseller: "",
  roomSize: {
    name: "",
    abre: ""
  },
  typeScreen: {
    name: "",
    abre: "",
    type: ""
  },
  mobilitySolution: {
    name: "",
    abre: ""
  },
  ops: {
    name: "",
    abre: ""
  },
  videoConferencing: {
    name: "",
    abre: ""
  },
  wirelessSharing: {
    name: "",
    abre: ""
  }
});

const optionsReducer = (state = getInitialState(), action) => {
  switch(action.type) {
    case TOGGLESIDE:
      return { ...state, toggleSide: !action.toggleSide };
    case SETROOMSIZE:
      return { ...state, roomSize: action.roomSize };
    case SETTYPESCREEN:
      return { ...state, typeScreen: action.typeScreen };
    case SETMOBILITY:
      return { ...state, mobilitySolution: action.mobilitySolution };
    case SETOPS:
      return { ...state, ops: action.ops };
    case SETVIDEO:
      return { ...state, videoConferencing: action.videoConferencing };
    case SETWIRELESS:
      return { ...state, wirelessSharing: action.wirelessSharing };
    case SETSUBDOMAIN:
      return { ...state, reseller: action.reseller };
    default:
      return state;
  }
}

export default optionsReducer;