import React from 'react';
import { withRouter } from 'react-router-dom';
import './SideMenu.styles.css';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setToggle } from '../../redux/actions/optionActions';

//Icons
import closeMenu from '../../assets/images/icons/closeMenu.png';
import logo from '../../assets/images/logo.png';

//Room Size
import roomSmall from '../../assets/images/icons/roomSmall.png';
import roomMedium from '../../assets/images/icons/roomMedium.png';
import roomLarge from '../../assets/images/icons/roomLarge.png';
//Type Screen
import hdiScreen from '../../assets/images/icons/screenInteractive.png';
import samScreen from '../../assets/images/icons/screenDisplay.png';
//Mobility Solution
import wallAdjustable from '../../assets/images/icons/wallAdjustable.png';
import wallFixed from '../../assets/images/icons/wallFixed.png';
import wallMobile from '../../assets/images/icons/wallMobile.png';
//OPS
import imgOps from '../../assets/images/icons/ops.png';
import imgNoOps from '../../assets/images/icons/noOps.png';
//Video Conferencing
import imgVideoConferencing from '../../assets/images/icons/videoConferencing.png';
import imgNoVideoConferencing from '../../assets/images/icons/noVideoCon.png';
//Wireless
import wireless from '../../assets/images/icons/wireless.png';
import noWireless from '../../assets/images/icons/noWireless.png';

function SideMenu(props){
  const dispatch = useDispatch();
  const toggle = useSelector(state => state.optionsReducer.toggleSide)
  const { step, history } = props;

  const roomSize = useSelector(state => state.optionsReducer.roomSize);
  const typeScreen = useSelector(state => state.optionsReducer.typeScreen);
  const mobilitySolution = useSelector(state => state.optionsReducer.mobilitySolution);
  const ops = useSelector(state => state.optionsReducer.ops);
  const videoConferencing = useSelector(state => state.optionsReducer.videoConferencing);
  const wirelessSharing = useSelector(state => state.optionsReducer.wirelessSharing);

  const navigateTo = (route) => {
    history.replace(route);
  }

  return(
    <div className="contentSide">
      <div className="logosMobile" onClick={() => dispatch(setToggle(toggle))}>
        <img src={logo} alt="logo" className="logoMobile"/>
        <img src={closeMenu} alt="close" className="closeMobile"/>
      </div>
      <p className="titleSide">Room Tech</p>
      <ul>
        {step >= 1 && <li className="customFlex">
          <img src={roomSize.abre === 'S' ? roomSmall : roomSize.abre === 'M' ? roomMedium : roomLarge} alt="icon" className="imgIconSide" />
          <div className="contentTexts">
            <p className="txtTitleSide">Room Size</p>
            <p className="txtDescSide">{roomSize.name}</p>
          </div>
          <div className="editIcon" onClick={() => navigateTo('/room-size')}></div>
        </li>}
        {step >= 2 && <li className="customFlex">
          <img src={typeScreen.type === 'HDi' ? hdiScreen : samScreen} alt="icon" className="imgIconSide" />
          <div className="contentTexts">
            <p className="txtTitleSide">Screen</p>
            <p className="txtDescSide">{typeScreen.name}</p>
          </div>
          <div className="editIcon" onClick={() => navigateTo('/type-screen')}></div>
        </li>}
        {step >= 3 && <li className="customFlex">
          <img src={mobilitySolution.abre === 'WA' ? wallAdjustable : mobilitySolution.abre === 'WF' ? wallFixed : wallMobile} alt="icon" className="imgIconSide" />
          <div className="contentTexts">
            <p className="txtTitleSide">Configuration</p>
            <p className="txtDescSide">{mobilitySolution.name}</p>
          </div>
          <div className="editIcon" onClick={() => navigateTo('/mobility-solution')}></div>
        </li>}
        {step >= 4 && typeScreen.type === 'HDi' && <li className="customFlex">
          <img src={ops.abre === 'OY' ? imgOps : imgNoOps} alt="icon" className="imgIconSide" />
          <div className="contentTexts">
            <p className="txtTitleSide">OPS</p>
            <p className="txtDescSide">{ops.name}</p>
          </div>
          <div className="editIcon" onClick={() => navigateTo('/ops')}></div>
        </li>}
        {step >= 5 && <li className="customFlex">
          <img src={videoConferencing.abre === 'VY' ? imgVideoConferencing : imgNoVideoConferencing} alt="icon" className="imgIconSide" />
          <div className="contentTexts">
            <p className="txtTitleSide">Video Conferencing</p>
            <p className="txtDescSide">{videoConferencing.name}</p>
          </div>
          <div className="editIcon" onClick={() => navigateTo('/video-conferencing')}></div>
        </li>}
        {step >= 6 && <li className="customFlex">
          <img src={wirelessSharing.abre === 'WY' ? wireless : noWireless} alt="icon" className="imgIconSide" />
          <div className="contentTexts">
            <p className="txtTitleSide">Wireless Sharing</p>
            <p className="txtDescSide">{wirelessSharing.name}</p>
          </div>
          <div className="editIcon" onClick={() => navigateTo('/wireless-sharing')}></div>
        </li>}
      </ul>
    </div>
  )
}

export default withRouter(SideMenu);