import React, { useState } from 'react';
import { Container, Col, Row, Modal, ModalBody } from 'reactstrap';
import './TypeScreen.styles.css';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { selectTypeScreen } from '../../redux/actions/optionActions';

//Images
import interactive from '../../assets/images/tvInteractive.png';
import display from '../../assets/images/tvDisplay.png';

//Components
import Header from '../Header/Header';
import Steps from '../Steps/Steps';
import SideMenu from '../SideMenu/SideMenu';

function TypeScreen(props) {
  const dispatch = useDispatch();
  const toggleSide = useSelector(state => state.optionsReducer.toggleSide);
  const [showVideo, setShowVideo] = useState(false);
  const toggle = () => setShowVideo(!showVideo);
  const roomSize = useSelector(state => state.optionsReducer.roomSize);

  const goToMobility = (opt) => {
    var obj = {
      name: "",
      abre: "",
      type: ""
    };
    switch(roomSize.abre){
      case 'S':
        obj.name = opt === 'HDi' ? `HDi edge 65"` : `Samsung 65"`;
        obj.abre = opt === 'HDi' ? `HDi_65` : `SAM_65`;
        obj.type = opt;
        break;
      case 'M':
        obj.name = opt === 'HDi' ? `HDi edge 75"` : `Samsung 75"`;
        obj.abre = opt === 'HDi' ? `HDi_75` : `SAM_75`;
        obj.type = opt;
        break;
      case 'L':
        obj.name = opt === 'HDi' ? `HDi edge 86"` : `Samsung 82"`;
        obj.abre = opt === 'HDi' ? `HDi_86` : `SAM_82`;
        obj.type = opt;
        break;
    }

    dispatch(selectTypeScreen(obj));
    props.history.push('/mobility-solution');
  }

  return (
    <Container className="customContainer">
      <Header title={'Type of Screen'} showMobileMenu={true} />
      <Row>
        <Col xs="12" md="3"></Col>
        <Col xs="12" md="6" style={{textAlign: 'center', paddingTop: 30}}>
          <p className="subtitleType">What type of screen do you need?</p>
          <div className="rowIcons">
            <div className="playIcon"></div>
            <p className="textIcon" onClick={toggle}>{`Watch comparison video >`}</p>
          </div>

          <Row>
            <Col xs="6" md="6">
              <img className="imgScreen" src={interactive} alt=""/>

              <div className="btnScreen" onClick={() => goToMobility('HDi')}>
                <span className="txtBtn">Interactive</span>
              </div>
            </Col>
            <Col xs="6" md="6">
              <img className="imgScreen" src={display} alt=""/>

              <div className="btnScreen" onClick={() => goToMobility('SAM')}>
                <span className="txtBtn">Display</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Steps active={2} />
          </Row>
        </Col>
        {toggleSide && <Col xs="12" md="3" className="slideMenuColType" style={{paddingRight: 0}}>
          <SideMenu step={1} />
        </Col>}
      </Row>

      <Modal isOpen={showVideo} toggle={toggle} size="lg">
        <div className="closeIcon" onClick={toggle}>X</div>
        <ModalBody>
          <iframe width="100%" height="415" src="https://www.youtube.com/embed/6-VbVc5kBrM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </ModalBody>
      </Modal>
    </Container>
  )
}

export default TypeScreen;