import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Components
import Home from './components/Home/Home';
import RoomSize from './components/RoomSize/RoomSize';
import TypeScreen from './components/TypeScreen/TypeScreen';
import MobilitySolution from './components/MobilitySolution/MobilitySolution';
import Ops from './components/Ops/Ops';
import VideoConferencing from './components/VideoConferencing/VideoConferencing';
import WirelessSharing from './components/WirelessSharing/WirelessSharing';
import YourRoom from './components/YourRoom/YourRoom';
import ThankYou from './components/ThankYou/ThankYou';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/room-size" component={RoomSize} />
        <Route exact path="/type-screen" component={TypeScreen} />
        <Route exact path="/mobility-solution" component={MobilitySolution} />
        <Route exact path="/ops" component={Ops} />
        <Route exact path="/video-conferencing" component={VideoConferencing} />
        <Route exact path="/wireless-sharing" component={WirelessSharing} />
        <Route exact path="/your-room" component={YourRoom} />
        <Route exact path="/thank-you" component={ThankYou} />
      </Switch>
    </Router>
  );
}

export default App;
