import React from 'react';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import './Header.styles.css';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setToggle } from '../../redux/actions/optionActions';

function Header(props) {

  const openMenu = () => {
    props.setToggle(props.toggleSide);
  }

  const goToStart = () => {
    props.history.replace('/');
  }

  return (
    <div className="headerContent">
      <Row className="customRow">
        <Col sm="3" style={{textAlign: 'right', position: 'relative'}}>
          <div className="contentLogo" onClick={goToStart}></div>
          {props.showMobileMenu && <div className="sideMobile" onClick={() =>  openMenu()}></div>}
        </Col>
        <Col sm="6">
          <p className="title">{props.title}</p>
        </Col>
        <Col sm="3"></Col>
      </Row>
    </div>
  )
}

const mapStateToProps = state => {
  return{
    toggleSide: state.optionsReducer.toggleSide
  }
}

const mapDispatchToProps = dispatch => {
  return{
    setToggle: bindActionCreators(setToggle, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));