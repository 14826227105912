import React, { useState } from 'react';
import { Container, Col, Row, Modal, ModalBody } from 'reactstrap';
import './Ops.styles.css';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { selectOps } from '../../redux/actions/optionActions';

//Images
import ops from '../../assets/images/ops.png';

//Components
import Header from '../Header/Header';
import Steps from '../Steps/Steps';
import SideMenu from '../SideMenu/SideMenu';

function Ops(props) {
  const dispatch = useDispatch();
  const toggleSide = useSelector(state => state.optionsReducer.toggleSide);
  const [showVideo, setShowVideo] = useState(false);
  const toggle = () => setShowVideo(!showVideo);

  const goToVideo = (opt, abrv) => {
    let obj = {
      name: opt,
      abre: abrv
    }
    dispatch(selectOps(obj));
    props.history.push('/video-conferencing');
  }

  return (
    <Container className="customContainer">
      <Header title={'OPS'} showMobileMenu={true} />
      <Row>
        <Col xs="12" md="3"></Col>
        <Col xs="12" md="6" style={{textAlign: 'center'}} className="topOPS">
          <Row>
            <Col xs="12" md="6">
              <img className="imgOps" src={ops} alt=""/>
            </Col>
            <Col xs="12" md="6" className="centerWhite">
              <div className="contentDescription">
                <p className="mainText">Did you know all HDi edge screens have the ability to house a {"\n"} built-in PC?</p>
                <p className="parentText">(known as an OPS)</p>
                <p className="secondText">When you switch on your screen this powers up a fully interactive Windows / Android interface.</p>
                <div className="rowIcons">
                  <div className="playIcon"></div>
                  <p className="playText" onClick={toggle}>{`Watch More >`}</p>
                </div>
              </div>
            </Col>
          </Row>

          <Row style={{justifyContent: 'center'}}>
            <p className="secondSubtitle">Do you want an in-built computer (OPS)?</p>
            <div className="contentBtns">
              <div className="btnWhite" onClick={() => goToVideo('Yes', 'OY')}>
                <span className="txtBtn">Yes</span>
              </div>
            
              <div className="btnWhite" onClick={() => goToVideo('No', 'ON')}>
                <span className="txtBtn">No</span>
              </div>
            </div>
          </Row>

          <Row>
            <Steps active={4} />
          </Row>
        </Col>
        {toggleSide && <Col xs="12" md="3" className="slideMenuCol" style={{paddingRight: 0}}>
          <SideMenu step={3} />
        </Col>}
      </Row>

      <Modal isOpen={showVideo} toggle={toggle} size="lg">
        <div className="closeIcon" onClick={toggle}>X</div>
        <ModalBody>
          <iframe title="ops-video" width="100%" height="415" src="https://www.youtube.com/embed/YJGxgi-OnlU" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </ModalBody>
      </Modal>
    </Container>
  )
}

export default Ops;